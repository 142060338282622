:root {
    --color-primary: #a02b22;
    --color-secondary: #0098db;
    --color-accent: #ff8a01;
}

/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

@tailwind utilities;

body {
    hyphens: auto;
}

#header {
    height: 110px;
    transition: background-color 400ms;
    z-index: 10;
}

#menu-div {
    position: fixed;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: right;
    transition: width 400ms;
    overflow: hidden;
}

@media(min-width: 768px) {
    #menu-div {
        position: relative;
        width: auto;
        flex-direction: row;
    }
}

.hero {
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.webp #hero-project {
    background-image: linear-gradient(to bottom,rgba(0,0,0,0.50) 2%,rgba(0,0,0,0.74) 64%,rgba(0,0,0,0.81) 100%), url(images/dino-hero3.webp);
}

#hero {
    @extend .hero;
    background-image: linear-gradient(to bottom,rgba(0,0,0,0.50) 2%,rgba(0,0,0,0.74) 64%,rgba(0,0,0,0.81) 100%), url(/images/dino-hero3.jpg);
}

.webp #hero-project {
    background-image: linear-gradient(to bottom,rgba(0,0,0,0.50) 2%,rgba(0,0,0,0.74) 64%,rgba(0,0,0,0.81) 100%), url(images/dino-hero2.webp);
}

#hero-project {
    @extend .hero;
    background-image: linear-gradient(to bottom,rgba(0,0,0,0.50) 2%,rgba(0,0,0,0.74) 64%,rgba(0,0,0,0.81) 100%), url(/images/dino-hero2.jpg);
}

.webp #hero-museum {
    background-image: linear-gradient(to bottom,rgba(0,0,0,0.50) 2%,rgba(0,0,0,0.74) 64%,rgba(0,0,0,0.81) 100%), url(images/dino-hero1.webp);
}

#hero-museum {
    @extend .hero;
    background-image: linear-gradient(to bottom,rgba(0,0,0,0.50) 2%,rgba(0,0,0,0.74) 64%,rgba(0,0,0,0.81) 100%), url(/images/dino-hero1.jpg);
}

.webp #hero-ehrc {
    background-image: linear-gradient(to bottom,rgba(0,0,0,0.50) 2%,rgba(0,0,0,0.74) 64%,rgba(0,0,0,0.81) 100%), url(images/dinosaur-60588.webp);
}

#hero-ehrc {
    @extend .hero;
    background-image: linear-gradient(to bottom,rgba(0,0,0,0.50) 2%,rgba(0,0,0,0.74) 64%,rgba(0,0,0,0.81) 100%), url(images/dinosaur-60588.jpg);
}

.input-group {
    position: relative;
    width: 100%;

    label {
        position: absolute;
        padding: 0;
        margin: 0;
        top: 18px;
        left: 10px;
        transition: top 400ms, font-size 400ms;
    }
    input {
        width: 100%;
        padding: 18px 10px;
        background-color: darken(white, 10%);
        border: none;
        outline: none;
    }

    &.active {
       label {
           top: 0;
           font-size: 0.8rem;
       }
    }
}